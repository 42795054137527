import { Button } from "@9amhealth/shared";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";

//#region Styled components
const Card = styled.div`
  label: AnnouncementCard;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 2rem 1.5rem;
  text-align: center;
`;

const CardTitle = styled.h4`
  margin: 0 0 0.25rem;
`;

const CardSubtitle = styled.p`
  color: var(--color-charcoal-80, rgba(33, 33, 33, 0.8));
  margin: 0 0 1.5rem;
`;

const ImgSection = styled.div`
  position: relative;
  margin: -2rem -1.5rem 0;
  overflow: hidden;

  img {
    display: block;
  }
`;
//#endregion

export interface AnnouncementCardProps {
  img: {
    src: string;
    alt: string;
  };
  title: string;
  subtitle: string;
  buttonText: string;
  buttonAction: () => void;
  show: boolean;
}

const AnnouncementCard: FC<AnnouncementCardProps> = ({
  img,
  title,
  subtitle,
  buttonText,
  buttonAction
}) => {
  return (
    <Card>
      <ImgSection className="announcement-card-gradient-bg">
        <img src={img.src} alt={img.alt} />
      </ImgSection>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle>{subtitle}</CardSubtitle>
      <Button center onPress={buttonAction}>
        {buttonText}
      </Button>
    </Card>
  );
};

export default AnnouncementCard;
