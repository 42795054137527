import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { IconCaretToRightSmallArrow } from "src/constants/icons";
import translate from "src/lib/translate";
import ProgramBloc, {
  KnownProgram,
  KnownProgramInfo
} from "src/state/ProgramBloc/ProgramBloc";
import { useBloc } from "src/state/state";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import ResponsiveImage from "src/ui/components/ResponsiveImage/ResponsiveImage";
import ProgramTaskItem from "src/ui/components/TaskItem/ProgramTaskItem";
import TextWithKeywords from "src/ui/components/TextWithKeywords/TextWithKeywords";
import Translate from "src/ui/components/Translate/Translate";

const Container = styled.div``;

const ImageTextCard = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div`
  label: ImageWrapperProgramPreviewCard;
  background: var(--color-charcoal);
  overflow: hidden;
  aspect-ratio: 1;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0.6) 62%
    );
    pointer-events: none;
    z-index: 1;
  }

  @media screen and (min-width: 490px) {
    border-radius: 0.6rem;
    margin-top: 1.8rem;
    &::after {
      border-radius: 0.6rem;
    }
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  z-index: 2;
  color: white;
  left: 0;
  bottom: 0;
  text-align: left;
  width: 100%;
  padding: var(--space-md);
  font-size: calc(var(--scale) * 1);
`;

const ProgramTitle = styled.h1`
  font-size: calc(var(--scale) * 1.7);
  margin: 0 0 0.5rem 0;
  text-wrap: pretty;
`;

const ProgramDescription = styled.p`
  font-size: calc(var(--scale) * 1.2);
  margin: 0;
`;

const BlueLink = styled(Link)`
  color: var(--color-sunrise-blue) !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 0.3em;
`;

const NoProgramSpaceHolder = styled.div`
  height: var(--safe-area-top, 0px);
  margin-bottom: 1.2rem;
`;

const ProgramPreviewCard: FC<{
  program?: KnownProgram;
  hideLink?: boolean;
  hideActions?: boolean;
}> = ({ program, hideActions, hideLink }) => {
  const [
    { autoProgramDetected },
    { getProgramContent, checkUserProfileProgramData }
  ] = useBloc(ProgramBloc, {});
  const knownProgram =
    program ?? autoProgramDetected ?? KnownProgram.WEIGHT_MANAGEMENT;
  const { title, description, slug, image } = getProgramContent(knownProgram);

  useEffect(() => {
    void checkUserProfileProgramData();
  }, []);

  const definedTitle = useMemo<string>(() => {
    if (!title) {
      return "";
    }

    if (!knownProgram) {
      return title;
    }

    const knownTitle = KnownProgramInfo[knownProgram]?.title;

    if (!knownTitle) {
      return title;
    }

    return translate(knownTitle);
  }, [knownProgram, title]);

  const definedDescription = useMemo<string>(() => {
    if (!description) {
      return "";
    }

    if (!knownProgram) {
      return description;
    }

    const knownDescription = KnownProgramInfo[knownProgram]?.description;

    if (!knownDescription) {
      return description;
    }

    return translate(knownDescription);
  }, [description, knownProgram]);

  if (!knownProgram) {
    return <NoProgramSpaceHolder />;
  }

  return (
    <Container>
      <ImageTextCard>
        <AsyncContent check={[image]} height="14rem">
          <ImageWrapper>
            <ResponsiveImage
              alt={definedTitle}
              image={image}
              customAspectRatio={1}
            />
          </ImageWrapper>
        </AsyncContent>

        <TextWrapper>
          <ProgramTitle className="as-h4-large m0">{definedTitle}</ProgramTitle>
          <ProgramDescription>
            <TextWithKeywords text={definedDescription} />
          </ProgramDescription>
        </TextWrapper>
      </ImageTextCard>

      {(!hideLink || !hideActions) && <nine-spacer s="md"></nine-spacer>}

      {!hideLink && (
        <AsyncContent check={[slug]} width="160px" height="1.2rem">
          <BlueLink to={`/app/program/${slug}`}>
            <Translate msg="program.module.show" />
            <IconCaretToRightSmallArrow />
          </BlueLink>
        </AsyncContent>
      )}

      {!hideActions && (
        <ProgramTaskItem
          program={program}
          select={[
            { status: TaskResponse.status.AVAILABLE },
            { status: TaskResponse.status.IN_PROGRESS },
            { status: TaskResponse.status.LOCKED }
          ]}
        />
      )}
    </Container>
  );
};

export default ProgramPreviewCard;
