import { Cubit } from "blac-next";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import React from "react";

export interface CarouselState {
  activeIndex: number;
  cardWidth: number;
  cardGap: number;
  locked: boolean;
}

export class CarouselCubit extends Cubit<CarouselState> {
  ref: React.RefObject<HTMLDivElement | null> = React.createRef();

  // unique prefix for carousel items
  itemPrefix = Math.random().toString(36).substring(7);

  constructor() {
    super({
      activeIndex: 0,
      cardWidth: APP_CONTENT_WIDTH_WITHOUT_PADDING,
      cardGap: 16,
      locked: false
    });
  }

  private lockScrolling = (): void => {
    this.patch({ locked: true });

    setTimeout(() => {
      this.patch({ locked: false });
    }, 300);
  };

  public readonly syncCarouselState = () => {
    const carousel = this.ref.current;

    if (!carousel) {
      return;
    }

    const { clientWidth } = carousel;

    const computed = getComputedStyle(carousel);

    const elementWidth =
      clientWidth -
      parseFloat(computed.paddingLeft) -
      parseFloat(computed.paddingRight);
    const gap = parseFloat(computed.columnGap);

    const { scrollLeft } = carousel;

    const index = Math.round(scrollLeft / elementWidth);

    this.patch({
      activeIndex: index,
      cardWidth: elementWidth,
      cardGap: gap
    });
  };

  public readonly handleScrollTo = (direction: "next" | "prev") => {
    const carousel = this.ref.current;

    if (!carousel || this.state.locked) {
      return;
    }

    this.lockScrolling();

    const { activeIndex, cardWidth, cardGap } = this.state;

    const elementsCount = Array.from(carousel.children).filter((node) =>
      node.id.startsWith(this.itemPrefix)
    ).length;

    const maxIndex = elementsCount - 1;

    if (direction === "next") {
      if (activeIndex === maxIndex) {
        carousel.scrollBy({
          left: -cardWidth * maxIndex - cardGap * 2,
          behavior: "smooth"
        });
      } else {
        carousel.scrollBy({ left: cardWidth + cardGap, behavior: "smooth" });
      }
    } else {
      if (activeIndex === 0) {
        carousel.scrollBy({
          left: cardWidth * maxIndex + cardGap * 2,
          behavior: "smooth"
        });
      } else {
        carousel.scrollBy({ left: -cardWidth - cardGap, behavior: "smooth" });
      }
    }
  };

  public readonly handleScroll = () => {
    const carousel = this.ref.current;

    if (!carousel) {
      return;
    }

    this.syncCarouselState();
  };
}
