import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useLayoutEffect } from "react";
import { useBloc } from "src/state/state";
import ArticleCard from "src/ui/components/UserEducationalFeed/ArticleCard";
import type { EducationalContent } from "src/ui/components/UserEducationalFeed/UserEducationalFeedBloc";
import UserEducationalFeedBloc from "src/ui/components/UserEducationalFeed/UserEducationalFeedBloc";
import AsyncContent from "../AsyncContent/AsyncContent";
import Translate from "src/ui/components/Translate/Translate";
import { type Category, DefaultCategory } from "src/lib/getEducationalContent";
import { IconCheckmarkButton } from "src/constants/icons";
import ReactDOM from "react-dom";
import { useInView } from "react-intersection-observer";
import StatusBarBloc from "src/hybrid/components/StatusBarBloc";
import TaskManagementBloc from "src/state/TaskManagementBloc/TaskManagementBloc";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import clsx from "clsx";

const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  margin-top: var(--space-sm);
`;

const ButtonRow = styled.div`
  display: flex;
  overflow-x: auto;
  gap: var(--space-xs);
  margin: -1em calc(var(--space-md) * -1) -2.5em -4.5em;
  padding: 1em var(--space-md) 2.5em 5em;

  //hide scrollbar all browsers
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &[data-status-bar="false"] {
    margin-right: calc(var(--space-lg) * -3);
    padding-right: calc(var(--space-lg) * 3);
    margin-bottom: -2em;
  }

  @media screen and (min-width: 730px) {
    flex-wrap: wrap;
    overflow: initial;
  }

  @media (orientation: landscape) {
    [data-render-portal="true"] & {
      flex-wrap: initial;
      overflow-x: scroll;
      justify-content: safe center;
    }
  }
`;

interface Props {
  categories: Category[];
  selectedCategory: Category;
  handleClickOnCategory: (category: Category, e?: Event) => void;
  isStatusBar: boolean;
}

const CategoryFilter: FC<Props> = (props: Props) => {
  return (
    <ButtonRow data-status-bar={props.isStatusBar}>
      {props.categories.map((category: Category) => (
        <nine-button
          class={clsx('filter-button', { "is-selected": props.selectedCategory === category })}
          size="sm"
          key={category + props.isStatusBar.toString()}
          color="black"
          variant={props.selectedCategory === category ? "fill" : "outline"}
          arrow=""
          onClick={(e?: Event) => props.handleClickOnCategory(category, e)}
        >
          {props.selectedCategory === category && <IconCheckmarkButton />}
          <div style={{ marginLeft: "2px" }}>
            <Translate msg="category" variables={{ context: category }} />
          </div>
        </nine-button>
      ))}
    </ButtonRow>
  );
};

const UserEducationalFeed: FC = () => {
  const [selectedCategory, setSelectedCategory] = React.useState<Category>(
    DefaultCategory.all
  );
  const [{ articles, categories }, { loadEducationalContent }] = useBloc(
    UserEducationalFeedBloc,
    {
      create: () => new UserEducationalFeedBloc()
    }
  );
  const [, { setRenderPortal }] = useBloc(StatusBarBloc);
  const [, { getCompletedProgramsTasks, getProgramsTasks }] =
    useBloc(TaskManagementBloc);

  const programs = [KnownProgram.ONBOARDING, KnownProgram.CARE];
  const tasks = getProgramsTasks(programs);
  const completedTasks = getCompletedProgramsTasks(programs);

  const handleClickOnCategory = (category: Category, e?: Event) => {
    e?.preventDefault();
    if (category === selectedCategory) {
      setSelectedCategory(DefaultCategory.all);
    } else {
      setSelectedCategory(category);
    }
  };

  const filteredArticles = [
    ...new Set(
      selectedCategory === DefaultCategory.all
        ? articles
        : articles.filter((article: EducationalContent) =>
            article.categories.some(
              (category) => category.slug === selectedCategory
            )
          )
    )
  ];

  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 730);
  const [isLandscape, setIsLandscape] = React.useState(
    window.matchMedia("(orientation: landscape)").matches
  );
  const isTouchDevice = !!(
    "ontouchstart" in window || navigator.maxTouchPoints
  );

  useEffect(() => {
    void loadEducationalContent(tasks);
  }, [completedTasks.length]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 730);
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      setRenderPortal(false);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { ref, inView } = useInView({
    threshold: 0
  });
  const targetRef = React.useRef(null);
  const [isBelow, setIsBelow] = React.useState(false);
  const [elementTop, setElementTop] = React.useState(0);

  useLayoutEffect(() => {
    if (targetRef.current) {
      const topPosition = (
        targetRef.current as HTMLElement
      ).getBoundingClientRect().top;
      setElementTop(topPosition);
    }
  }, []);

  useEffect(() => {
    if (!inView && targetRef.current) {
      const targetTop =
        (targetRef.current as HTMLElement).getBoundingClientRect().top || 0;
      setIsBelow(targetTop < elementTop);
    }
  }, [inView]);

  const renderCategoryFilterAsTopBar =
    (isMobile || (isLandscape && isTouchDevice)) && !inView && isBelow;

  useEffect(() => {
    if (categories.length > 0 && renderCategoryFilterAsTopBar) {
      setRenderPortal(true);
    } else {
      setRenderPortal(false);
    }
  }, [renderCategoryFilterAsTopBar, categories.length]);

  return (
    <div>
      {categories.length === 0 ? (
        <ArticleGrid>
          {new Array(4).fill("").map((_, index) => (
            <AsyncContent key={index} height="6rem" />
          ))}
        </ArticleGrid>
      ) : (
        <>
          <div ref={ref}>
            <div ref={targetRef}>
              <CategoryFilter
                data-render-portal={renderCategoryFilterAsTopBar}
                categories={categories}
                selectedCategory={selectedCategory}
                handleClickOnCategory={handleClickOnCategory}
                isStatusBar={false}
              />
            </div>
          </div>
          <div>
            {renderCategoryFilterAsTopBar &&
              ReactDOM.createPortal(
                <CategoryFilter
                  isStatusBar={true}
                  categories={categories}
                  selectedCategory={selectedCategory}
                  handleClickOnCategory={handleClickOnCategory}
                />,
                //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                document.getElementById("portal")!
              )}
          </div>
          <ArticleGrid>
            {filteredArticles.map((article, index) => (
              <ArticleCard key={index} article={article} />
            ))}
          </ArticleGrid>
        </>
      )}
    </div>
  );
};

export default UserEducationalFeed;
