import styled from "@emotion/styled";
import type { FC } from "react";
import React, { createRef, useEffect } from "react";
import Translate from "../Translate/Translate";
import {
  IconBloodPressureHeartWithClock,
  IconCrossXCloseMissed,
  IconLabsBloodGlucoseDropWithSugarCube,
  IconWeightScale
} from "src/constants/icons";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";
import IconMeasureRuler from "src/ui/assets/icons/IconMeasureRuler";
import { createPortal } from "react-dom";
import translate from "src/lib/translate";
import { APP_BREAKPOINT } from "src/constants/layout";

const ActionOverlay = styled.div`
  label: ActionOverlay;
  position: fixed;
  height: 100vh;
  height: 100svh;
  inset: 0;
  z-index: 99999999;

  &[aria-hidden="true"] {
    pointer-events: none;
  }
`;

const PlusButton = styled.button`
  background: transparent;
  transform: rotate(45deg);
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--safe-area-top, 0);
`;

const ActionButtons = styled.div`
  label: ActionButtons;
  tab-index: 0;
  outline: none;
  position: absolute;
  left: calc(var(--left) - 10rem);
  top: calc(var(--top, 0px) + 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.6rem;
  transform: translateY(0.5rem);
  opacity: 0;
  transform-origin: left bottom;
  transition:
    transform 0.2s ease-out,
    opacity 0.2s ease-out;

  [aria-hidden="false"] & {
    transform: translateY(0);
    opacity: 1;
  }

  @media screen and (max-width: ${APP_BREAKPOINT}px) {
    --top: 70px;
  }
`;

const Backdrop = styled.div`
  label: Backdrop;
  position: absolute;
  inset: 0;
  background-color: rgba(66, 66, 66, 0.6);
  opacity: 0;
  transition: opacity 0.2s ease-out;

  [aria-hidden="false"] & {
    opacity: 1;
    backdrop-filter: blur(0.1rem);
  }
`;

const DataHubTitleRow: FC = () => {
  const actionButtonOverlay = createRef<HTMLDivElement>();
  const actionButton = createRef<HTMLButtonElement>();
  const buttonWrap = createRef<HTMLDivElement>();
  const [showOverlay, setShowOverlay] = React.useState(false);

  const reposition = () => {
    const btn = actionButton.current;
    const overlay = actionButtonOverlay.current;
    const btnWrap = buttonWrap.current;

    if (!btn || !overlay || !btnWrap) return;
    const { left } = btn.getBoundingClientRect();

    overlay.style.setProperty("--top", `155px`);
    overlay.style.setProperty("--left", `${left}px`);
  };

  const openOverlay = () => {
    const btnWrap = buttonWrap.current;

    if (!btnWrap) return;

    setShowOverlay(true);
    reposition();

    setTimeout(() => {
      btnWrap.focus();
    }, 100);
  };

  const handleClose = () => {
    setShowOverlay(false);
  };

  useEffect(() => {
    reposition();
  }, [actionButtonOverlay.current, buttonWrap.current]);

  return (
    <TitleWrap>
      <h2>
        <Translate msg="dataHub.title" />
      </h2>
      <PlusButton
        ref={actionButton}
        aria-label={translate("dataHub.addEntry")}
        onClick={(e) => {
          e.preventDefault();
          openOverlay();
        }}
      >
        <IconCrossXCloseMissed size={32} aria-hidden />
      </PlusButton>
      {createPortal(
        <ActionOverlay
          ref={actionButtonOverlay}
          aria-hidden={showOverlay ? "false" : "true"}
          onClick={handleClose}
        >
          <Backdrop />
          <ActionButtons ref={buttonWrap} tabIndex={0}>
            <nine-button
              arrow=""
              size="md"
              variant="outline-fill"
              id="bg"
              shadow="always"
              onClick={() =>
                AppQueryPopupsController.openPopup(AppPopup.enterBloodGlucose)
              }
            >
              <div slot="pre">
                <IconLabsBloodGlucoseDropWithSugarCube />
              </div>
              <Translate msg="bloodGlucose" />
            </nine-button>

            <nine-button
              arrow=""
              size="md"
              variant="outline-fill"
              id="bp"
              shadow="always"
              onClick={() =>
                AppQueryPopupsController.openPopup(AppPopup.enterBloodPressure)
              }
            >
              <div slot="pre">
                <IconBloodPressureHeartWithClock />
              </div>
              <Translate msg="bloodPressure" />
            </nine-button>

            <nine-button
              arrow=""
              size="md"
              variant="outline-fill"
              id="waist"
              shadow="always"
              onClick={() =>
                AppQueryPopupsController.openPopup(
                  AppPopup.enterWaistCircumference
                )
              }
            >
              <div slot="pre">
                <IconMeasureRuler />
              </div>
              <Translate msg="waistCircumference" />
            </nine-button>

            <nine-button
              arrow=""
              size="md"
              variant="outline-fill"
              id="weight"
              shadow="always"
              onClick={() =>
                AppQueryPopupsController.openPopup(AppPopup.enterWeight)
              }
            >
              <div slot="pre">
                <IconWeightScale />
              </div>
              <Translate msg="weight" />
            </nine-button>
          </ActionButtons>
        </ActionOverlay>,
        document.getElementById("actionButtonTarget") as Element
      )}
    </TitleWrap>
  );
};

export default DataHubTitleRow;
