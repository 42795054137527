import { TaskResponse, UpdateTaskRequest } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useMemo } from "react";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import translate from "src/lib/translate";
import { onboardingCompletedTask } from "src/state/OnboardingBloc/OnboardingBloc";
import { tracker, useBloc } from "src/state/state";
import TaskManagementBloc, {
  TaskKey,
  TaskResponseKnown
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import AnnouncementCard, {
  AnnouncementCardProps
} from "src/ui/components/AnnouncementCard/AnnouncementCard";
import Track from "src/ui/components/Track/Track";

//#region Styled Components
const DialogWrapper = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 999999;
`;

const DialogContent = styled.div`
  max-width: ${APP_CONTENT_WIDTH}px;
`;
//#endregion

const AnnouncementCardDialog: FC = () => {
  const [{ tasks }, { getTask, updateTaskStatus }] =
    useBloc(TaskManagementBloc);

  const task = useMemo<TaskResponseKnown | undefined>(() => {
    return getTask(TaskKey.COMPLETE_ONBOARDING);
  }, [tasks]);

  const announcementCardProps = useMemo<
    AnnouncementCardProps | undefined
  >(() => {
    return {
      img: {
        src: "https://cdn.sanity.io/images/g38rxyoc/production/244889a96d4f1f4c050a649aaf6b1715a5c768fe-686x352.webp",
        alt: translate("announcementCard.home.image.alt")
      },
      title: translate("announcementCard.home.title"),
      subtitle: translate("announcementCard.home.subtitle"),
      buttonText: translate("announcementCard.home.buttonText"),
      buttonAction: () => {
        void updateTaskStatus(
          onboardingCompletedTask,
          UpdateTaskRequest.status.COMPLETED
        ).then(() => {
          tracker.track(TrackEvent.AnnouncementCardCTA, {
            data: { type: TrackType.click, from: "cta" }
          });
        });
      },
      show: task?.status === TaskResponse.status.IN_PROGRESS
    };
  }, [task?.status]);

  return (
    <>
      {announcementCardProps?.show && (
        <DialogWrapper>
          <DialogContent>
            <Track event={TrackEvent.AnnouncementCard} type={TrackType.open} />
            <AnnouncementCard {...announcementCardProps} />
          </DialogContent>
        </DialogWrapper>
      )}
    </>
  );
};

export default AnnouncementCardDialog;
