import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC } from "react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { APP_BREAKPOINT, TOP_NAV_HEIGHT } from "src/constants/layout";
import type Path from "src/constants/path";
import translate from "src/lib/translate";
import AppViewCubit, { navItems } from "src/state/AppViewCubit/AppViewCubit";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { useBloc } from "src/state/state";
import logo from "src/ui/assets/9amhealth.svg";
import AppRouter from "src/ui/components/AppRouter/AppRouter";
import Loader from "src/ui/components/Loader/Loader";
import MobileNav from "src/ui/components/MobileNav/MobileNav";
import MobileNavItem from "src/ui/components/MobileNav/MobileNavItem";
import NavBadge from "src/ui/components/NavBadge/NavBadge";
import DemoBadge from "src/ui/components/StyledComponents/DemoBadge";
import Track from "src/ui/components/Track/Track";

const WidthContainer = styled.div`
  width: 620px;
  flex: 1;
  margin: 0 auto;
  max-width: 100%;
`;

const fadeInPage = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const PageWrap = styled.div`
  label: AppPageWrap;
  animation: ${fadeInPage} 0.3s ease-in-out;
`;

const TopBar = styled.div`
  label: TopBar;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: calc(${TOP_NAV_HEIGHT}px + var(--safe-area-top, 0px));
  display: none;
  background: var(--app-top-bar-bg, rgba(255, 255, 255, 1));
  border-bottom: 1px solid var(--color-cream-darker);
  padding-top: var(--safe-area-top, 0px);

  nav {
    display: flex;
    padding: 0;
    align-items: center;
    margin: 0 2em 0 0.5em;
    flex: 1;

    & > img {
      width: 175px;
      display: block;
      height: auto;
      margin-right: 1.4em;
    }
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    display: flex;
  }
`;

const NavList = styled.ul`
  label: NavList;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 14px;
  align-items: center;
  grid-gap: 1.2em;
  height: ${TOP_NAV_HEIGHT}px;
  flex: 1;
  justify-content: flex-start;
`;

const NavItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  .nav-badge {
    pointer-events: none;
    color: white;
    left: 1.9rem;
    --size: 1.1rem;
  }

  &::before {
    content: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    grid-gap: 0.2em;
    align-items: center;
    font-weight: 600;
    line-height: 1;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }

  &:last-of-type {
    margin-left: auto;
  }

  &.selected {
    a {
      opacity: 1;
    }
  }

  &.locked {
    a {
      opacity: 0.6;
      pointer-events: none;

      img {
        --size: 1.1rem;
      }
    }
  }

  img {
    display: block;
    transform: translateY(-1px);
    --size: 1.65rem;
    width: var(--size);
    height: var(--size);
    margin-right: 0.2em;
  }
`;

const AppPage: FC = () => {
  const location = useLocation();
  const [{ view }, { setPageByPath, setTabBarVisible, setInApp }] =
    useBloc(AppViewCubit);
  const [, { isDemoUser }] = useBloc(UserPreferencesCubit);

  useEffect(() => {
    const path = location.pathname.replace("/app/", "") as Path;
    setPageByPath(path);
    setInApp(true);

    return () => {
      setInApp(false);
    };
  }, [location]);

  useEffect(() => {
    setTabBarVisible(true);

    if ((location.pathname as Path) !== view.path) {
      setPageByPath(location.pathname as Path);
    }
  }, [location]);

  const isActivePath = (path: Path): boolean => {
    return location.pathname.startsWith(`/app/${path}`);
  };

  const currentPageRoot = location.pathname.split("/")[2] as Path;

  return (
    <PageWrap>
      {isDemoUser && <DemoBadge> DEMO </DemoBadge>}
      <Loader loadingKey={LoadingKey.logout} gradient />

      <div>
        <Helmet>
          <title>
            {translate("page_name")} - {translate(view.label)}
          </title>
        </Helmet>
        <Track event={TrackEvent.app} type={TrackType.open} />

        <div>
          <MobileNav page={currentPageRoot}>
            {navItems.map((item) => (
              <MobileNavItem
                key={item.label}
                label={translate(item.label)}
                value={item.path}
                icon={item.icon}
              />
            ))}
          </MobileNav>

          <TopBar>
            <nav>
              <img src={logo} alt="9amHealth Logo" />
              <NavList>
                {navItems.map((item) => (
                  <NavItem
                    key={item.label}
                    className={clsx({
                      selected: isActivePath(item.path)
                    })}
                  >
                    <Link to={item.path}>
                      {item.icon && (
                        <img
                          src={
                            isActivePath(item.path)
                              ? item.icon.active
                              : item.icon.normal
                          }
                          alt=""
                        />
                      )}
                      <span>{translate(item.label)}</span>
                    </Link>
                    <NavBadge path={item.path} />
                  </NavItem>
                ))}
              </NavList>
            </nav>
          </TopBar>

          <div>
            <WidthContainer>
              <AppRouter />
            </WidthContainer>
          </div>
        </div>
      </div>
    </PageWrap>
  );
};

export default AppPage;
