import { Button } from "@9amhealth/shared";
import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import loincCodeToName from "src/lib/loincCodeToName";
import translate from "src/lib/translate";
import DataHubCubit, {
  DataHubAvailableLoincCodes
} from "src/ui/components/DataHub/DataHubCubit";

//#region Styled components
const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  margin: -1em calc(var(--space-md) * -1) -2.5em -5em;
  padding: 2em var(--space-md) 4.25em 5em;

  //hide scrollbar all browsers
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
//#endregion

const DataTabs: FC = () => {
  const [{ activeLoincCode }, { setActiveObservations }] =
    useBloc(DataHubCubit);

  return (
    <Wrapper>
      {DataHubAvailableLoincCodes.map((key) => (
        <Button
          key={key}
          hideArrow
          size="small"
          onPress={() => {
            void setActiveObservations({ loincCode: key });
          }}
          active={activeLoincCode === key}
          theme="blur-charcoal"
        >
          <small className="strong">
            {translate(loincCodeToName(key), { context: "dataHub" })}
          </small>
        </Button>
      ))}
    </Wrapper>
  );
};

export default DataTabs;
