import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { APP_BREAKPOINT } from "src/constants/layout";
import type Path from "src/constants/path";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import { useBloc } from "src/state/state";

const MobileNavPosition = styled.nav`
  label: MobileNavPosition;
  position: fixed;
  --link-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  background: var(--color-cream);
  background: var(--greys-light-gray, #e6e3db);
  padding-bottom: calc(var(--ion-safe-area-bottom, 0px) + 0px);

  &[data-page="chat"] {
    background: transparent;
  }

  @media (min-width: ${APP_BREAKPOINT}px) {
    display: none;
  }
`;

const TabNavigation = styled.div`
  label: TabNavigation;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999990;
  pointer-events: all;
  background: #fff;
  font-size: calc(var(--scale) * 1.4rem);
  box-shadow: inset 0 -2px 0 0 var(--nav-border-color, #eee);
  transform: translateY(0);
  transition: transform: 0.3s ease-in-out;

  .backdrop-no-scroll & {
    transform: translateY(-100%);
  }

  > div {
    margin: 0 auto;
    width: min(440px, 100vw);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow: auto;
    border: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nav-item {
    padding: 0;
    position: relative;
    display: block;
    flex: 0;
    background: transparent;
    transition: padding 0.2s;

    &:has(.nav-badge--show) {
      padding-right: 1.7em;
    }

    &:after {
      content: "";
      display: block;
      height: 2px;
      --nav-border-color: transparent;
      background: var(--nav-border-color, transparent);
      transition: background 0.2s;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:has(a[aria-current="page"]) {
      z-index: 5;
      scroll-margin: 0;
      &:after {
        --nav-border-color: var(--nav-border-color-active, var(--color-sunrise-orange));
      }
    }

    a {
      padding: 14px 0.9em 9px;
    }
  }

  .nav-icon {
    display: none;
  }

  .nav-label {
    font-weight: 500;
    font-size: 1em;
    color: var(--nav-label-color);
    white-space: nowrap;
  }

  [aria-current="page"] .nav-label {
    color: var(--nav-label-color-active);
  }

  @media (min-width: ${APP_BREAKPOINT}px) {
    display: none;
  }
`;

const BottomAnchor = styled.div`
  label: BottomAnchor;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const MobileNav: FC<{
  children: React.ReactNode;
  page: Path;
}> = ({ children, page }) => {
  const location = useLocation();
  const [{ mobileTabNavigation }] = useBloc(AppViewCubit);

  useEffect(() => {
    if (mobileTabNavigation) {
      const active = document.querySelector(
        ".nav-item:has(a[aria-current=page])"
      );
      if (active) {
        active.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [location.pathname, mobileTabNavigation]);

  if (mobileTabNavigation) {
    return (
      <>
        <TabNavigation data-page={page}>
          <div>{children}</div>
        </TabNavigation>
        <BottomAnchor id="bottom" />
      </>
    );
  }

  return (
    <MobileNavPosition data-page={page} id="bottom">
      {children}
    </MobileNavPosition>
  );
};

export default MobileNav;
