import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";

import { TOP_NAV_HEIGHT } from "src/constants/layout";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import { useBloc } from "src/state/state";
import { ChatBloc } from "src/ui/components/Chat/ChatBloc";
import CustomSuspense from "src/ui/components/CustomSuspense/CustomSuspense";
import LocalErrorBoundary from "src/ui/components/SentryBoundary/LocalErrorBoundary";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import Translate from "src/ui/components/Translate/Translate";

const Async = {
  VirtualizedMessageList: React.lazy(
    async () => import("src/ui/components/Chat/VirtualizedMessageList")
  ),
  ChatInput: React.lazy(async () => import("src/ui/components/Chat/ChatInput"))
};

const ChatFrame = styled.div`
  label: ChatFrame;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  bottom: 0;
  box-shadow: 0 0 20em 20em var(--color-gray-lighter);

  --kb-offset: calc(var(--textfield-height, 0px));
  --v-msg-list-height: 100%;
  --additional-offset: calc(var(--stored-keyboard-height, 55px) - 55px);
  bottom: calc(var(--kb-offset));

  @media screen and (min-width: 730px) {
    top: calc(${TOP_NAV_HEIGHT}px + var(--safe-area-top, 0px));
    --additional-offset: var(--stored-keyboard-height, 0px);
  }

  .virtuoso {
    /* 3d for better scrolling */
    height: var(--v-msg-list-height, 100%);
    /* contain all scrolling in here */
    overscroll-behavior: contain;
  }

  &[data-focused="true"] {
    --kb-offset: calc(
      var(--additional-offset) + var(--textfield-height, 0px) - var(
          --ion-safe-area-bottom,
          0px
        )
    );
  }
`;

const loadingKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ChatLoadingIndicator = styled.div`
  position: absolute;
  top: calc(20px + var(--safe-area-top, 0px));
  left: 50%;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  transform: translate(-50%, 0%);
  z-index: 50;
  opacity: 1;
  transition:
    top 0.2s ease-out,
    opacity 0.2s ease-out;

  &::before {
    animation: ${loadingKeyframes} 1s linear infinite;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 0.25em solid transparent;
    border-top-color: var(--color-sunrise-blue);
    border-bottom-color: var(--color-sunrise-blue);
  }

  &[data-show="false"] {
    top: -2.5em;
    opacity: 0;
  }
`;

const NewMessageNotification = styled.button`
  position: absolute;
  bottom: calc(var(--ion-safe-area-bottom, 0px) + 160px);
  left: 50%;
  transform: translate(-50%, 10%);
  height: 2.5em;
  background: var(--color-sunrise-blue);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  cursor: pointer;
  padding: 0.6em 1em;
  border-radius: 1.25em;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  z-index: 50;
  pointer-events: none;

  &[data-show="true"] {
    opacity: 1;
    transform: translate(-50%, 0%);
    pointer-events: all;
  }

  body & {
    color: white;
  }
`;

const Chat: FC = () => {
  const [
    state,
    {
      postMessage,
      loadPreviousMessages,
      registerVirtualList,
      setScrollEndState,
      scrollToBottom,
      moreHistoryAvailable,
      setChatOpen,
      markMessageAsRead,
      initChat
    }
  ] = useBloc(ChatBloc);
  const frameRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    initChat({
      userId: StorageController.activeUserId ?? undefined
    });
    return () => {
      setChatOpen(false);
    };
  }, []);

  const updateBottomSpace = (h: number) => {
    const frameEl = frameRef.current;

    if (frameEl) {
      frameEl.style.setProperty("--textfield-height", `${h}px`);
    }
  };

  const handleFocusChange = (isFocused: boolean) => {
    frameRef.current?.setAttribute("data-focused", `${isFocused}`);
  };

  return (
    <LocalErrorBoundary
      fallback={
        <ErrorBox>
          <Translate msg="chat.error.load" />
        </ErrorBox>
      }
    >
      <ChatFrame ref={frameRef}>
        <ChatLoadingIndicator data-show={state.loading} />
        <CustomSuspense>
          <Async.VirtualizedMessageList
            firstItemIndex={state.firstItemIndex}
            messages={state.messages}
            moreHistoryAvailable={moreHistoryAvailable}
            onStartReached={() => void loadPreviousMessages()}
            onListInitialized={registerVirtualList}
            onBottomStateChange={setScrollEndState}
            markMessageAsRead={markMessageAsRead}
          />
        </CustomSuspense>

        <NewMessageNotification
          data-show={state.newMessageAvailable}
          onClick={() => scrollToBottom(true)}
        >
          <Translate msg="chat.newMessage" />
        </NewMessageNotification>
      </ChatFrame>
      <div ref={inputRef}>
        <CustomSuspense>
          <Async.ChatInput
            onChange={updateBottomSpace}
            onSubmit={postMessage}
            onFocusChange={handleFocusChange}
            errors={state.errors}
          />
        </CustomSuspense>
      </div>
    </LocalErrorBoundary>
  );
};

export default Chat;
